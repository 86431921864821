<template>
  <div>
    <van-sticky :offset-top="50">
      <van-button type="primary" style="position: absolute" @click="onShowItemPopup()">选择分类</van-button>
      <van-button type="primary" icon="comment-o" style="position: absolute; top:45px" @click="onToKF()">客服</van-button>
<!--      <van-image-->
<!--          @click="onToKF()"-->
<!--          round-->
<!--          fit="cover"-->
<!--          width="50px"-->
<!--          height="50px"-->
<!--          :src="kfImgUrl"-->
<!--      />-->
    </van-sticky>
<!--    <van-sticky :offset-top="100">-->
<!--      -->
<!--    </van-sticky>-->
    <div class="address-popup-load">
      <van-loading v-if="showLoading && init" type="spinner" color="#1989fa"/>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="!init">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="item in goods" :key="item.id" :name="item.id" @click="showImages(item.id)">
          <van-card style="padding: 8px 1px;"
                    :title="item.storeName"
                    :desc="item.storeInfo"
                    :num="item.stock"
                    :thumb="item.image"
          >
            <template #footer>
              <wx-open-launch-weapp
                  id="launch-btn"
                  :username="username"
                  :path="path + item.id"
                  @launch="handleLaunchFn"
                  @error="handleErrorFn"
              >
                <script type="text/wxtag-template">
                  <style>
                    .ant-btn {
                      line-height: 1.499;
                      position: relative;
                      display: inline-block;
                      font-weight: 400;
                      white-space: nowrap;
                      text-align: center;
                      background-image: none;
                      border: 1px solid #d9d9d9;
                      -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
                      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
                      cursor: pointer;
                      -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
                      transition: all .3s cubic-bezier(.645, .045, .355, 1);
                      -webkit-user-select: none;
                      -moz-user-select: none;
                      -ms-user-select: none;
                      user-select: none;
                      -ms-touch-action: manipulation;
                      touch-action: manipulation;
                      height: 32px;
                      padding: 0 15px;
                      font-size: 14px;
                      border-radius: 4px;
                      color: rgba(0, 0, 0, 0.65);
                      background-color: #fff;
                    }

                    .ant-btn-red {
                      color: #fff;
                      background-color: #FF5A44;
                      border-color: #FF5A44;
                      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
                      -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
                      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
                    }
                  </style>
                  <button class="ant-btn ant-btn-red">马上购</button>
                </script>
              </wx-open-launch-weapp>

            </template>
          </van-card>
        </div>

      </van-list>
    </van-pull-refresh>
    <van-popup v-model="showItemPopup" position="left">
      <van-sidebar v-model="activeKey">
        <div v-for="item in items" :key="item.id" :name="item.id">
          <van-sidebar-item :title="item.cateName" @click="showAddAlert(item.id)"/>
        </div>
      </van-sidebar>
    </van-popup>

    <van-image-preview v-model="show" :images="images" @change="onChange">
      <template v-slot:index>第{{ index }}页</template>
    </van-image-preview>
  </div>
</template>

<script>

import {
  Button,
  Card,
  Checkbox,
  CheckboxGroup,
  Col,
  Dialog,
  Image,
  ImagePreview,
  List,
  Loading,
  Notify,
  Popup,
  PullRefresh,
  Row,
  Sidebar,
  SidebarItem,
  Sticky,
  SubmitBar,
  Tag,
  TreeSelect
} from 'vant';

import * as productService from "@/api/serverData/getServerData";
import {wechatEvevt} from "@/libs/wechat";
import {getWechatConfig} from "@/api/public";
import $store from "@/store";
import kfImgUrl from "@/static/image/kf.png";

const wx = require('weixin-js-sdk');
let wechatObj;

export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Popup.name]: Popup,
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Notify.name]: Notify,
    [Col.name]: Col,
    [Row.name]: Row,
    [Loading.name]: Loading,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Sticky.name]: Sticky,
    [Dialog.name]: Dialog,
    [TreeSelect.name]: TreeSelect,

  },

  data() {
    return {
      showItemPopup: false,
      active: 0,
      empty: false,
      init: true,
      activeKey: 0,
      goods: [],
      items: [],
      show: false,
      showLoading: true,
      index: 0,
      images: [
        'https://img.yzcdn.cn/vant/apple-1.jpg',
        'https://img.yzcdn.cn/vant/apple-2.jpg',
      ],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      itemId: null,
      username: 'gh_57ffdf702c70', // gh_ 开头的原始小程序ID
      path: 'pages/shop/GoodsCon/index.html?id=', // 一定要以 .html 结尾
      kfImgUrl: kfImgUrl,
    };
  },
  watch: {
    urlPath: function (val) {
      this.fullName = val + ' ' + this.lastName
    },
    lastName: function (val) {
      this.fullName = this.firstName + ' ' + val
    }
  },
  created() {
    productService.getItems().then(res => {
      if (res.status === 200) {
        this.init = false;
        this.items = res.data;
        this.showLoading = false;
        if (this.items.length > 1) {
          this.showLoading = true;
          this.itemId = this.items[0].id;
          this.onRefresh();
        } else {
          this.empty = true;
          Dialog.alert({
            message: "管理员正在整理您的商品价格，请耐心等待～",
            theme: 'round-button',
          }).then(() => {
            wechatEvevt('closeWindow')
          });
        }
      } else {
        this.showLoading = false;
        this.finished = true;
        Dialog.alert({
          message: res.msg,
          theme: 'round-button',
        }).then(() => {
          wechatEvevt('closeWindow')
        });
      }
    });
  },
  methods: {
    onToKF() {
        this.$router.push({path: '/websocket/websocketGreetings'})
    },
    onShowItemPopup() {
      this.showItemPopup = true
    },
    showAddAlert(itemId) {
      this.showItemPopup = false
      this.toMiniapp();
      this.goods = [];
      this.itemId = itemId;
      this.resetData();
      this.onRefresh();
    },
    formatPrice(price) {
      return (price / 100).toFixed(2);
    },
    showImages(id) {
      this.show = !this.show;
      this.goods.forEach(item => {
        if (item.id === id) {
          this.images = item.sliderImage.split(',');
        }
      })
    },
    onChange(index) {
      this.index = index;
    },
    onLoad() {
      if (this.refreshing) {
        this.goods = [];
        this.refreshing = false;
      }
      if (this.itemId !== null) {
        this.showLoading = true;
        let param = 'sid=' + this.itemId + '&page=' + this.pages.currPage + '&limit=10';
        let that = this;
        productService.getProducts(param).then(res => {
          if (res.status === 200) {
            that.goods = that.goods.concat(res.data.records)
            this.showLoading = false;
            this.loading = false;
            that.pages.currPage++;
            that.pages.pageCount = res.data.pages;
            if (that.pages.currPage > that.pages.pageCount) {
              that.finished = true;
            }
          }
        });
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
    handleLaunchFn(e) {
      console.log("success", e);
    },
    handleErrorFn(e) {
      console.log("fail", e.detail);
    },
    toMiniapp() {
      $store.dispatch("setWxRedirectUrl", {wxRedirectUrl: location.href}).then(() => {
        console.log('执行 actions setWxRedirectUrl 操成功', $store)
        getWechatConfig().then(res => {
          window.console.log(res)
          wx.config({
            debug: false,
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: res.data.jsApiList, // 必填，如果只是为了跳转小程序，随便填个值都行
            openTagList: ["wx-open-launch-weapp"] // 跳转小程序时必填
          });
          wx.ready(function () {
            //微信获取地理位置并拉取用户列表（用户允许获取用户的经纬度）
            console.log('wx-open-launch-weapp');
          })
        }).catch(err => {
          console.log(err)
        });
      }).catch((err) =>  console.log(err))
      // getWechatConfig().then(res => {
      //   window.console.log(res)
      //   wx.config({
      //     debug: false,
      //     appId: res.data.appId, // 必填，公众号的唯一标识
      //     timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      //     nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
      //     signature: res.data.signature, // 必填，签名
      //     jsApiList: res.data.jsApiList, // 必填，如果只是为了跳转小程序，随便填个值都行
      //     openTagList: ["wx-open-launch-weapp"] // 跳转小程序时必填
      //   });
      //   wx.ready(function () {
      //     //微信获取地理位置并拉取用户列表（用户允许获取用户的经纬度）
      //     console.log('wx-open-launch-weapp');
      //   })
      // }).catch(err => {
      //   console.log(err);
      // });
    }
  },
  mounted() {
    this.toMiniapp()
  }
};
</script>

<style lang="less">
.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
